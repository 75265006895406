import Button from "../../Button/Button";
import "./styles.css";

export default function CardHome({
  title,
  description,
  onClick,
  imgStyles,
  image,
  icon
}: any) {
  return (
    <article className="card" onClick={onClick}>
      {icon && <div className="card__icon">{icon}</div>}
      <img
        className="card__background"
        src={image}
        alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
        width="1920"
        height="2193"
        style={imgStyles}
      />
      <div className="card__content | flow">
        <div className="card__content--container | flow">
          <h2 className="card__title">{title}</h2>
          <br />
          <p className="card__description"></p>
        </div>
        <Button
          buttonStyles={{
            width: "52%",
            margin: "auto",
            marginTop: "10px"
          }}
          label="Entrar"
        />
      </div>
    </article>
  );
}
