import { useHistory } from "react-router-dom";
import CardHome from "./components/home/Card/Card";
import Grid from "@mui/material/Unstable_Grid2";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";
import ContainerWrapper from "./components/ContainerWrapper/ContainerWrapper";
import HeaderTitle from "./components/HeaderTitle/HeaderTitle";
import { useUserState } from "./state/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faCircleDollarToSlot,
  faPrescription
} from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default function Home() {
  const { user } = useUserState();
  const history = useHistory();
  const classes = useStyles();

  const cardsList = [
    {
      id: 1,
      title: "Assinar Prescrições Pendentes",
      fn: () => (window.location.href = "https://sign.myscribo.com/"),
      image: "menu2.jpg",
      imgStyles: {
        objectPosition: "right bottom"
      },
      icon: <FontAwesomeIcon icon={faPrescription} size="3x" color="white" />
    },
    {
      id: 2,
      title: "Conta de Pagamentos",
      fn: () => history.push("/payments-account/resume"),
      image: "menu1.jpg",
      imgStyles: {
        objectPosition: "left bottom"
      },
      icon: (
        <FontAwesomeIcon icon={faCircleDollarToSlot} size="3x" color="white" />
      )
    },
    {
      id: 3,
      title: "Compras",
      fn: () => history.push("/cmds/buy"),
      image: "menu3.jpg",
      imgStyles: {
        objectPosition: "left bottom"
      },
      icon: <FontAwesomeIcon icon={faCartShopping} size="3x" color="white" />
    }
  ];

  const getTitle = (user: any) => {
    const basedOnGender: any = {
      0: "Bem-vindo, ",
      1: "Bem-vindo, Dr. ",
      2: "Bem-vinda, Dra. "
    };

    return `${basedOnGender[user.personalInfo.gender]}`;
  };

  return (
    <ContainerWrapper>
      <HeaderTitle>
        {getTitle(user)}
        <b>{user.clinicalName}</b>!
      </HeaderTitle>

      <Grid spacing={2} container className={classes.gridContainer}>
        {(cardsList as any).map((data: any) => (
          <Grid sm={6} md={4} lg={3} xl={3} key={data.id}>
            <CardHome
              title={data.title}
              description={data.description}
              key={data.id}
              onClick={data.fn}
              image={data.image}
              imgStyles={data.imgStyles}
              icon={data.icon}
            />
          </Grid>
        ))}
      </Grid>
    </ContainerWrapper>
  );
}
